import React, { Component } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
  Button,
} from "reactstrap";
import industries from "../../../variables/industries.json";
import geographies from "../../../variables/geographies.json";
import channels from "../../../variables/channels.json";
import types from "../../../variables/types.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBlockPresence } from "./report";
import ImageMemorability from "./../blocks/ImageMemorability";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
class MemorabilityTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: false,
    };
  }

  getScoreMemorability(score) {
    //Score not needed in this case
  }

  componentDidUpdate() {
    if (this.state.opacity == false && this.props.task._id !== undefined) {
      this.setState({
        opacity: `/platform/attachments/serveattachment?name=memorabilityopacity&id=${this.props.task._id}&token=${this.props.token}`,
      });
    }
  }

  setGif(gif) {
    if (gif == true) {
      console.log("gif");
      this.setState({
        opacity: `/platform/attachments/serveattachment?name=memorabilitygif&id=${this.props.task._id}&token=${this.props.token}`,
      });
    } else {
      console.log("op");

      this.setState({
        opacity: `/platform/attachments/serveattachment?name=memorabilityopacity&id=${this.props.task._id}&token=${this.props.token}`,
      });
    }
  }

  reprocess(todo) {
    var task = this.props.task;
    console.log("todo,", todo);
    console.log("task,", task);

    if (todo === "mcu" && (task.industry === "" || task.type === "")) {
      this.setState({
        submitted: false,
        errors:
          "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }

    if (todo === "mcu") {
      todo = ["mcu", "memorability"];
    } else {
      todo = [todo];
    }

    axios
      .post("/platform/tasks/edit", {
        ...task,
        tasks: task.reports.concat(todo),
        id: task._id,
      })
      .then((res) => {
        console.log("this.props", this.props);
        this.props.history.push("/projects/" + task.project._id);
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    var access = this.props.access;
    return (
      <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <>
              {!access.includes("memorability") ||
              task.reports.indexOf("memorability") === -1 ? (
                <span>
                  <Row className="mt-4 px-4">
                    <Col xs="12 fade-in" className="disabled-grayscale">
                      <Row>
                        <div className="text-center mt-3 mb-3 w-100">
                          {task.media === "video" ? (
                            <h3 className="warning">
                              This functionnality is not available for videos
                              yet, here is a sample for an image:
                            </h3>
                          ) : !this.props.access.includes("memorability") ? (
                            <h3 className="warning">
                              This functionnality is not available in your plan,
                              here is a sample report. To get access, please
                              contact{" "}
                              <a href="mailto:crisp.team">
                                crisp.team
                              </a>
                              . Thank you!
                            </h3>
                          ) : (
                            <div>
                              {" "}
                              <h3 className="success">
                                This functionnality is available. <br></br>{" "}
                                Click on the button to perform it on this
                                content. <br></br>{" "}
                              </h3>
                              <Button
                                onClick={() => this.reprocess("memorability")}
                                color="danger"
                              >
                                PROCESS
                              </Button>
                            </div>
                          )}
                        </div>
                      </Row>
                      <Row className="border-row no-top-border mt-3">
                        <Col className="py-2 text-title" xs="4">
                          Human Memorability prediction
                        </Col>
                        <Col className="py-2 text-title dark-background" xs="3">
                          Score
                        </Col>
                        <Col className="py-2 text-title dark-background" xs="3">
                          Label
                        </Col>
                        {/* <Col className="py-2 text-title" xs="2">
    DETAILS
  </Col> */}
                      </Row>
                      <Row>
                        <Col xs="4" className="py-3">
                          <div>
                            <CardImg
                              top
                              width="100%"
                              className="card-img-results"
                              src={"/img/sample-memorability.jpg"}
                              alt="Card image cap"
                            />
                          </div>
                        </Col>
                        <Col xs="3" className="py-3 dark-background">
                          0.9
                        </Col>
                        <Col xs="3" className="py-3 dark-background">
                          Positive
                        </Col>
                        {/* <Col xs="2" className="py-3">
    <div>Details</div>
  </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </span>
              ) : (
                <Row className="mt-4 px-4">
                  <Col xs="4 fade-in">
                    <b>Original</b>
                    <Card>
                      <LazyLoadImage
                        placeholderSrc={
                          "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                        }
                        effect="blur"
                        className="card-img-results"
                        src={`/platform/attachments/serveinput?id=${task._id}&token=${token}`}
                      />
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Human Memorability Heatmap</b>
                    <CheckBlockPresence
                      analysis={task.analysis}
                      block="image_memorability"
                    >
                      <Card>
                        <LazyLoadImage
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className="card-img-results"
                          src={`/platform/attachments/serveattachment?name=memorability&id=${task._id}&token=${token}`}
                        />
                      </Card>
                    </CheckBlockPresence>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Human Memorability Opacity</b>
                    <CheckBlockPresence
                      analysis={task.analysis}
                      block="image_memorability"
                    >
                      <div
                        onMouseEnter={() => this.setGif(true)}
                        onMouseLeave={() => this.setGif(false)}
                      >
                        <Card>
                          <LazyLoadImage
                            placeholderSrc={
                              "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                            }
                            effect="blur"
                            className="card-img-results w-100"
                            src={this.state.opacity}
                          />
                        </Card>
                      </div>
                    </CheckBlockPresence>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default MemorabilityTab;
