import React, { Component } from "react";
import {
  Navbar,
  Container,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class NavbarPublic extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <Navbar expand="lg" className="navbar-absolute navbar-transparent home">
 
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </NavbarToggler>
          <Collapse
            className="justify-content-end mr-5 "
            isOpen={this.state.isOpen}
            navbar
          >
            <Nav navbar          className="text-menu">
              {/* <NavMenuItem
                location={this.props.location}
                path="/landing"
                name="Home"
                icon="home"
              ></NavMenuItem> */}
              <NavMenuItem
                location={this.props.location}
                path="/login"
                name="Login"
       
                icon="sign-in-alt"
              ></NavMenuItem>
              <NavMenuItem
                location={this.props.location}
                path="/register"
                name="Register"
                
                icon="user-plus"
              ></NavMenuItem>

<NavMenuItem
                location=""
                path={false}
                name="Get more information"
                mailto=""
                icon="info"
              ></NavMenuItem>
            </Nav>
          </Collapse>

      </Navbar>
    );
  }
}

const NavMenuItem = (props) => {
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <NavItem className={props.path ? activeRoute(props.path) : ''}>
      <NavLink href={props.path ? props.path : props.mailto} className="nav-link mr-4" activeClassName="active">
        <p>
          <FontAwesomeIcon className="mr-2" icon={props.icon} />
          {props.name}
        </p>
      </NavLink>
    </NavItem>
  );
};

export default NavbarPublic;
